<template>
  <div :id="uniqueId" class="small-section results-ad" />
</template>

<script>
import postscribe from 'postscribe';

export default {
  props: {
    adScript: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uniqueId: `result-ad-${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
    };
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      if (this.adScript !== '') {
        postscribe(`#${this.uniqueId}`, this.adScript);
      }
    }, 1000);
  },
};
</script>
